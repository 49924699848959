<template>
    <div class="videoList">
    <!-- <div class="topBar">
      <TopBarAdBlock v-if="mode == 1" />
      <TopBarDownloadBlock v-if="mode == 2" :onClick="handleClickDownload" />
    </div> -->
        <HeaderBar :hasArow="false" />
        <div class="first-card">
            <div class="card-left" @click="handleChangeVideo(firstVideo)">
                <img :src="firstVideo.tvImage" alt="">
            </div>
            <div class="card-right">
                <div class="title">
                    {{ firstVideo.tvName }}
                </div>
                <div class="weatched-tag-list">
                    <span>{{ firstVideo.updateWitch }} watching</span>
                </div>
                <div class="tag-list">
                    {{ firstVideo.tagText }}
                </div>
                <div class="desc">
                    {{ firstVideo.tvDesc }}
                </div>
                <div class="action">
                    <div class="btn" @click="handleChangeVideo(firstVideo)">
                        <img src="@/assets/play_black.png" alt="">
                        Watch
                    </div>
                    <div class="btn" @click="handleChangeVideo(firstVideo)">
                        <img src="@/assets/star.png" alt="">
                        View
                    </div>
                </div>
            </div>
        </div>

        <div class="list-wrap">
            <div class="list-title">
                Rankings
            </div>
            <div class="list-content">
                <div class="list-item" v-for="(item, index) in videoList" :key="index" @click="handleChangeVideo(item)">
                    <div class="head">
                        <div class="tag">{{ index + 1 }}</div>
                        <img :src="item?.tvImage" alt="">
                    </div>
                    <div class="title">{{ item?.tvName }}</div>
                    <div class="subtitle">{{ item?.allSeries }} Episode in total</div>
                </div>               
            </div>
        </div>
    </div>
</template>
<script>

// import TopBarAdBlock from "@/components/TopBarAdBlock";
// import TopBarDownloadBlock from "@/components/TopBarDownloadBlock";
import HeaderBar from "@/components/HeaderBar";

import api from '../service/index'
import utils from '@/utils/tools'

export default {
    name: "videoList",
    components: {
        // TopBarDownloadBlock,
        // TopBarAdBlock,
        HeaderBar,
    },
    data() {
        return {
            mode: '1', // ：1广告模式 2.引导APP下载。 默认1    
            loading: false,
            pageNum: 1,
            pageSize: 30,
            videoInfo: {},
            videoList: [],
            firstVideo: {},
        };
    },
    methods: {
        loadMore() {
            if (this.shouldLoadMore()) {
                if (this.loading) return;
                this.loading = true;

                api.geth5RankList({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                }).then((res) => {
                    if (res?.data?.list && res?.data?.list?.length > 0) {
                        this.videoList = [
                            ...this.videoList,
                            ...res.data.list
                        ];
                        if (this.pageNum == res?.data?.totalPage) {
                            this.loading = true;
                        } else {
                            this.loading = false;
                            this.pageNum++;
                        }

                    }
                });
            }
        },
        geth5IndexBox() {
            api.geth5IndexBox().then((res) => {
                this.firstVideo = res.data;
            })
        },
        // pv
        h5PvLog() {
            api.h5PvLog({
                tvid: 0,
                sid: 0,
                mode: this.mode,
                middlemanId: this.$route.query?.middlemanId || undefined,
                inviteCode: this.$route.query?.inviteCode || undefined
            }).then((res) => {
                console.log(res);
            })
        },
        handleChangeVideo(item) {
            this.$router.push({
                path: '/detail',
                query: {
                    tvid: item.id,
                    mode:  this.mode,
                    middlemanId: this.$route.query?.middlemanId || undefined,
                    inviteCode: this.$route.query?.inviteCode || undefined
                }
            })
        },
        handleClickDownload() {
            utils.downloadApp();
        },
        shouldLoadMore() {            
            // const windowHeight = window.innerHeight;
            // const scrollY = window.scrollY;
            // const bodyHeight = document.body.offsetHeight;        
            // // 检测滚动位置是否接近底部
            // const status = (bodyHeight - scrollY) <= windowHeight * 1.2;
            return true
        }
    },
    created() {
        this.mode = this.$route.query?.mode ? this.$route.query.mode : 1;
        this.geth5IndexBox();
        this.loadMore();
        this.h5PvLog();
    },
    mounted() {
        window.addEventListener('scroll', this.loadMore);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.loadMore);
    },
};
</script>
<style lang="less" scoped>
.videoList {

    .first-card {
        box-sizing: border-box;
        display: flex;
        border-radius: 16px;
        background: #F7F8FA;
        margin: 12px 12px 0 12px;
        padding-right: 12px;

        .card-left {
            img {
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
                display: block;
                width: 168px;
                height: auto;
            }
        }

        .card-right {
            box-sizing: border-box;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 16px;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            color: #666666;


            .title {
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #333333;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

            }

            .weatched-tag-list {
                margin-top: 8px;

                span {
                    padding: 4px;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 20px;
                    letter-spacing: 0em;
                    color: #EA894E;
                    border-radius: 8px;
                    background: #FEF1EF;
                }

            }

            .tag-list {
                margin-top: 8px;
            }

            .desc {
                margin-top: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;

            }

            .action {
                margin-top: 15px;
                display: flex;
                align-items: center;

                :first-child {
                    margin-right: 8px;
                }

                .btn {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 20px;
                    color: #3D3D3D;
                    padding: 4px 8px;
                    border-radius: 40px;
                    background: #FFFFFF;


                    img {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                    }
                }
            }

        }
    }



    .list-wrap {
        padding: 20px 12px 12px 12px;
        box-sizing: border-box;

        .list-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            color: #333333;
            padding-bottom: 12px;
        }

        .list-content {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 13px;
        }

        .list-item {

            .head {
                position: relative;
                border-radius: 10px;
                overflow: hidden;

                .tag {
                    position: absolute;
                    left: 0;
                    top: 0;

                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #EFA081;
                    border-top-left-radius: 10px;
                    border-bottom-right-radius: 10px;

                    font-size: 12px;
                    font-weight: 500;
                    line-height: 12px;
                    text-align: center;
                    color: #FFFFFF;

                }

                img {
                    display: block;
                    width: 110px;
                    height: 165px;
                    border-radius: 10px;
                }
            }

            &:nth-child(1) {
                .head .tag {
                    background: #F84928;
                }
            }

            &:nth-child(2) {
                .head .tag {
                    background: #F58E38;
                }
            }

            &:nth-child(3) {
                .head .tag {
                    background: #F8BC45;
                }
            }



            .title {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #3D3D3D;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .subtitle {
                font-size: 12px;
                font-weight: normal;
                line-height: 16px;
                color: #666666;
            }
        }
    }
}
</style>