var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"popup"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-desc"},[_c('p',{staticClass:"box-episodeList"},[_vm._v("Episode List")]),_c('p',{staticClass:"box-episodes"},[_vm._v("（"+_vm._s(this.total)+" episodes）")])]),_c('div',{staticClass:"tab-wrap"},[_c('div',{staticClass:"tab"},[_c('VSpace',{attrs:{"listData":_vm.pageList,"align":"start","split":true},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('div',{class:['tab-item', _vm.pageNum == slotProps?.item?.pageNumber ? 'tab-item_active' : ''],on:{"click":(e) => {
              e.stopPropagation();
              _vm.handleChangeVideoPage(slotProps?.item)
            }}},[_vm._v(" "+_vm._s(slotProps.item?.startNum)+"-"+_vm._s(slotProps.item?.endNum)+" ")])]}}])})],1)]),_c('div',{staticClass:"list-wrap"},_vm._l((_vm.videoList),function(listItem,key){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(key == _vm.pageNum),expression:"key == pageNum"}],key:key,staticClass:"list"},_vm._l((listItem),function(item){return _c('div',{key:item?.id,class:[
          'list-item',
          _vm.videoInfo?.id == item?.id ? 'list-item_playing' : (_vm.mode == 2 && item?.series >= _vm.startneedpay) ? 'list-item_lock' : '',
        ],on:{"click":(e) => {
            e.stopPropagation();
            _vm.handleChangeVideo(item)
          }}},[_c('div',{staticClass:"list-item-content"},[_vm._v(_vm._s(item?.series))])])}),0)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }