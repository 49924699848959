import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible' // 移动端适配
// import { setRem } from '@/utils/rem'
import './Normalize.css'
import router from './router.js'

// setRem()

import Ads from 'vue-google-adsense'

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')