// 工具函数
const tools = {
    // 获取localStorage
    getStorage(key, isSession) {
        isSession = isSession ? isSession : false;
        if (!key) {
            throw new Error("The getStorage key can not be empty!");
        }
        if (tools.getTypeOf(key) !== "String") {
            throw new Error("The getStorage key must be a string!");
        }
        const value = isSession ?
            window.sessionStorage.getItem(key) :
            window.localStorage.getItem(key);
        try {
            return JSON.parse(value || "");
        } catch (error) {
            if (value === "undefined") {
                return undefined;
            } else if (value === "null") {
                return null;
            } else {
                return value;
            }
        }
    },
    // 设置localStorage
    setStorage(key, item, isSession) {
        isSession = isSession ? isSession : false;
        if (!key) {
            throw new Error("The setStorage key can not be empty!");
        }
        if (typeof item === "object") {
            isSession
                ?
                window.sessionStorage.setItem(key, JSON.stringify(item)) :
                window.localStorage.setItem(key, JSON.stringify(item));
        } else {
            isSession
                ?
                window.sessionStorage.setItem(key, item) :
                window.localStorage.setItem(key, item);
        }
    },
    // 清除某个localStorage
    removeStorage(key) {
        window.localStorage.removeItem(key);
    },
    // 清空localStorage
    clearAllStorage() {
        window.localStorage.clear();
    },
    // 获取cookie
    getCookie(key) {
        const reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
        const arr = document.cookie.match(reg);

        if (arr) {
            return unescape(arr[2]);
        } else {
            return "";
        }
    },
    // 设置cookie
    setCookie(key, cvalue, exSecond, domain) {
        const d = new Date();
        d.setTime(d.getTime() + exSecond * 1000); // 秒
        const expires = "expires=" + d.toUTCString();
        if (domain) {
            document.cookie =
                key + "=" + cvalue + "; " + expires + "; path=/;domain=" + domain;
            return;
        } else {
            document.cookie = key + "=" + cvalue + "; " + expires + "; path=/";
        }
    },
    // 清除cookie
    clearCookie(key, domain) {
        tools.setCookie(key, "", -1, domain);
    },
    // 获取浏览器信息
    browser: (() => {
        const ua = navigator.userAgent;
        const appInfo = {
            // 移动终端浏览器版本信息
            mobile: !!ua.match(/AppleWebKit.*Mobile.*/) || !!ua.match(/AppleWebKit/), // 是否为移动终端
            ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1, // android终端或uc浏览器
            iPhone: ua.indexOf("iPhone") > -1, // 是否为iPhone或者QQHD浏览器
            iPad: ua.indexOf("iPad") > -1, // 是否iPad
            wx: ua.toLowerCase().indexOf("micromessenger") > -1, // 是否微信
        };

        return appInfo;
    })(),
    // 获取url 上的参数
    getUrlParam(name) {
        const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标参数的正则表达式对象
        const r = window.location.search.substr(1).match(reg); // 匹配目标参数
        if (r != null) {
            return unescape(r[2]);
        }
        return ""; // 返回参数值
    },
    // 获取对象类型
    getTypeOf(obj) {
        const objArr = Object.prototype.toString.call(obj);
        let type = objArr.match(/^\[object\s(.*)\]$/);
        if (type !== null) {
            type = type[1];
        }
        return type;
    },
    // 检测版本大小
    // vcompare(mixVersion, AppVersion) 4.6.6
    // vcompare('4.6.6', '4.6.7') //true or undefine
    vcompare(v1, v2) {
        try {
            const v1parts = v1.split(".");
            const v2parts = v2.split(".");
            const maxLen = Math.max(v1parts.length, v2parts.length);
            let part1;
            let part2;
            let cmp = 0;
            for (let i = 0; i < maxLen && !cmp; i++) {
                part1 = parseInt(v1parts[i], 10) || 0;
                part2 = parseInt(v2parts[i], 10) || 0;
                if (part1 < part2) {
                    cmp = 1;
                }
                if (part1 > part2) {
                    cmp = -1;
                }
            }
            if (cmp >= 0) {
                return true;
            }
        } catch (e) {
            throw new Error(e);
        }
    },
    downloadApp() {
        const { android, ios } = this.browser;
        let downloadUrl = "";
        if (android) {
            downloadUrl = "https://play.google.com/store/apps/details?id=com.yowanttv.video";
        } else if (ios) {
            downloadUrl = "https://apps.apple.com/us/app/yowanttv/id6476437957";
        }
        console.log('downloadUrl', downloadUrl);
        window.location.href = downloadUrl;
    },
};

export default tools;