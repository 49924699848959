<template>
    <div class="downloadBlock">
        <div class="downloadBlock-content">
            This episode needs to bedownloaded to watch
        </div>
        <div class="downloadBlock-action">
            <div class="downloadBlock-btn" @click="onClick">
                <img src="@/assets/download_icon.png" alt="">
                Download The App
            </div>
        </div>    
    </div>
</template>

<script>
export default {
    name: 'DownloadBlock',
    props: {
        onClick: {
            type: Function,
            default: ()=>{}
        }
    },
}
</script>

<style lang="less" scoped>
.downloadBlock {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;    
    background: #212121;	
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    

    &-content { 	
        width: 200px;
        margin: 0 auto;
        padding: 10px;
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0em;
            
        color: #FFFFFF;
	
    }
    &-action{
        margin-top: 20px;
    }

    &-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px 24px;
        border-radius: 253.5px;
        opacity: 1;
        background: linear-gradient(180deg, #FF5B43 0%, rgba(255, 91, 67, 0.49) 100%);

        font-size: 16px;
        font-weight: 500;
        line-height: 20px;	
        color: #FFFFFF;
	

        img{
            display: block;
            width: 13px;
            height: 13px;
            margin-right: 8px;
        }
    }
}
</style>