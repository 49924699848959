// 请求库封装
import axios from 'axios';

import tools from '@/utils/tools';

const { getCookie, setCookie } = tools;
const timeout = 30000;

const isPUblishENV = process.env.NODE_ENV === 'production'
    // const isPUblishENV = true;

// 交互里拿 token
// 站外 Cookie 获取授权访问信息
const TOKENKEY = 'token';
let TOKENVALUE = getCookie(TOKENKEY);
// 做了与原生交互的跨进程优化

const setHeader = axios.interceptors.request.use(async config => {
    // 设置交互超时时间   
    // if (TOKENVALUE) {
    // config.headers[TOKENKEY] = TOKENVALUE;
    // config.headers['sign'] = '37c716917c7721d345e1508062dcd6af';
    // config.headers['ts'] = '1718610948';
    // config.headers['token'] = 'f642af3e54ca423ab635c541c0d31d6b';
    // config.headers['Access-Control-Allow-Origin'] = '*';

    // }
    return config;
});


/** http 拦截器 */
/** 第一层，登录已失效 */
axios.interceptors.response.use(
    response => {
        const { code, message } = response.data;
        // 代表登录即将过期，重设置
        if (response.headers[TOKENKEY]) {
            TOKENVALUE = response.headers[TOKENKEY];
            const cookieExpress = 60 * 60 * 24 * 30; // 30天
            setCookie(TOKENKEY, TOKENVALUE, cookieExpress);

        }

        // 接口返回code不为0提示错误信息
        if (code !== 200) {
            // toast(message);
            console.log(message);
        }

        return response.data;
    },
    error => {
        console.log(error.message);
        if (error.message === 'Network Error') {
            // toast('似乎断开与互联网的连接了');
            alert('Seems to be disconnected from the Internet');
        } else if (error.message === `timeout of ${timeout}ms exceeded`) {
            // toast('网络连接超时');
            alert('Network connection timeout');
        } else {
            // toast('网络请求失败');
            alert('Network request failed');
        }
    }
);

const baseURL = isPUblishENV ? "//api.yowanttv.com/" : '//47.96.40.190:8088/';
// let baseURL = "//47.96.40.190:8088/";
// let baseURL = '';
const request = async(url, option) => {
    const options = {...option };
    options.timeout = options.timeout || timeout;

    /** http defaults */
    axios.defaults.baseURL = baseURL;

    // 默认非匿名请求
    if (option.anonymous) {
        axios.interceptors.request.eject(setHeader);
    }

    return axios({ url, ...options });
};

request.get = (url, option) => {
    const options = {...option };
    options.method = 'GET';
    options.params = options.params || {};
    return request(url, options);
};

request.post = (url, option) => {
    const options = {...option };
    options.method = 'POST';
    return request(url, options);
};

export default request;