<template>
  <div class="space-container" :style="{ justifyContent: justifyContent }">
    <div class="space-item" v-for="(item, index) in listData" :key="item.id">
      <slot :item="item" />
      <div class="divider" v-if="showDivider(index)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array, // 指定 prop 类型
      default: ()=> []
    },
    size: {
      type: String,
      default: "small",
      validator(value) {
        return ["small", "middle", "large"].includes(value);
      },
    },
    align: {
      type: String,
      default: "center",
      validator(value) {
        return ["start", "end", "center", "baseline", "stretch"].includes(
          value
        );
      },
    },
    split: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    children() {
      return this.$slots.default || [];
    },
    justifyContent() {
      
      return this.align === "start"
        ? "flex-start"
        : this.align === "end"
          ? "flex-end"
          : this.align === "center"
            ? "center"
            : this.align === "baseline"
              ? "flex-baseline"
              : "stretch";
    },
  },
  methods: {
    showDivider(index) {
      return this.split && index < this.listData.length - 1; // 这里可以根据需要调整分割线的显示规则
    },
  },
};
</script>

<style lang="less" scoped>
.space-container {
  display: flex;
  align-items: center;
  padding: 0 12px;
  flex-wrap: nowrap;
  box-sizing: border-box;
  margin-right: 10px;
}

.space-item {
  margin-right: var(--space-gap);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

/* 根据 size prop 设置不同的间距 */
.space-container {
  --space-gap: calc(var(--space-unit) * 2);
}

.space-container[size="small"] {
  --space-gap: calc(var(--space-unit) * 1);
}

.space-container[size="middle"] {
  --space-gap: calc(var(--space-unit) * 2);
}

.space-container[size="large"] {
  --space-gap: calc(var(--space-unit) * 3);
}

/* 分割线样式 */
.divider {
  width: 1px;
  height: 14px;
  // background-color: #e8e8e8;
  background: #E4E8F0;
  //margin: 0 calc(var(--space-unit) * 0.5);
  margin: 0 16px;
}
</style>