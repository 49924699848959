<template>
  <transition name="popup">
    <div class="box">
      <div class="box-desc">
        <p class="box-episodeList">Episode List</p>
        <p class="box-episodes">（{{ this.total }} episodes）</p>
      </div>
      <div class="tab-wrap">
        <div class="tab">
          <VSpace :listData="pageList" align="start" :split="true">
            <template v-slot:default="slotProps">
              <div :class="['tab-item', pageNum == slotProps?.item?.pageNumber ? 'tab-item_active' : '']" @click="(e) => {
                e.stopPropagation();
                handleChangeVideoPage(slotProps?.item)
              }">
                {{ slotProps.item?.startNum }}-{{ slotProps.item?.endNum }}
              </div>
            </template>
          </VSpace>
        </div>
      </div>

      <div class="list-wrap">
        <div class="list" v-for="(listItem, key) in videoList" v-show="key == pageNum" :key="key">
          <div v-for="item in listItem" :key="item?.id" :class="[
            'list-item',
            videoInfo?.id == item?.id ? 'list-item_playing' : (mode == 2 && item?.series >= startneedpay) ? 'list-item_lock' : '',
          ]" @click="(e) => {
              e.stopPropagation();
              handleChangeVideo(item)
            }">
            <div class="list-item-content">{{ item?.series }}</div>
          </div>
        </div>
      </div>
     
    </div>
  </transition>
</template>

<script>
import VSpace from "./VSpace.vue";

export default {
  name: "SelectVideo",
  components: {
    VSpace,
  },
  props: {
    videoList: {
      type: Object,
      required: true,
    },
    videoInfo: Object,
    pageNum: Number,
    pageSize: Number,
    total: Number,
    totalPage: Number,
    startneedpay: Number,
    mode: Number,
  },
  data() {
    return {
      dataList: {}
    };
  },
  methods: {
    handleChangeVideo(item) {
      this.$emit('changeVideo', item)
    },
    handleChangeVideoPage(pageItem) {
      this.$emit('changeVideoPage', pageItem.pageNumber)
    },
  },
  computed: {
    pageList() {
      let pageList = [];
      let startNum = 1;
      let endNum = startNum + this.pageSize;
      for (let i = 0; i < this.totalPage; i++) {
        startNum = (i) * this.pageSize + 1;
        endNum = startNum + this.pageSize - 1;
        pageList.push({
          startNum,
          endNum,
          pageNumber: i + 1
        })
      }
      return pageList
    }
  },
  created() { },
  mounted() { },
};
</script>

<style lang="less" scoped>
.box {
  &-desc {
    margin: 16px 0;
  }
  &-episodeList {
    /* Episode List */
    display: inline-block;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333
  }

  &-episodes {
    /* （50 episodes） */
    display: inline-block;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #BFBFBF
  }
}

.tab-wrap {
  width: 100%;
  overflow-y: scroll;
}

.tab {
  &-item {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;

    color: #999999;
    padding-top: 10px;
    padding-bottom: 10px;

    &_active {
      color: #E1452E;
    }
  }
}
.list-wrap{
  min-height: 270px;
}
.list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  grid-row-gap: 12px;
  grid-column-gap: 12px;

  padding: 10px 12px 32px 12px;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #333333;
    box-sizing: border-box;

    border-radius: 4px;
    opacity: 1;

    /* N3-线色 */
    background-color: #f2f2f2;

    &:hover {
      background: #FFEFED;
      border: 1px solid #E1452E;
      color: #E1452E;
    }

    &_playing {
      background-position: 5px 33px;
      // background-color: #f2f2f2;
      background-image: url('../assets/videoPlaying.png');
      background-repeat: no-repeat;
    }

    &_lock {
      background-position: right top;
      // background-color: #f2f2f2;
      background-size: 16px 16px;
      background-image: url('../assets/lock.png');
      background-repeat: no-repeat;
    }
  }
}
</style>