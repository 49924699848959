<template>
  <div class="headbar">
    <div class="headbar-left" @click="() => hasArow ? clickLift() : () => { }">
      <img v-if="hasArow" src="../assets/arrow.png" alt="" />
    </div>
    <div class="headbar-content">
      <img src="../assets/topLogo.png" alt="">
    </div>
    <div class="headbar-right"></div>
  </div>
</template>

<script>
export default {
  name: 'HeaderBar',
  props: {
    hasArow: {
      type: Boolean,
      require: true
    },
    clickLift: {
      type: Function,
      default: () => { },
    },
  }
}
</script>

<style lang="less" scoped>
.headbar {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-left {
    display: block;
    width: 7px;
    height: 13px;
    padding-left: 10px;

    img {
      display: inline-block;
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
    }
  }

  &-content {
    img {
      height: 22px;
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>