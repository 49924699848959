<template>
  <transition name="popup">
    <div class="popup-mask" @click="closeInfoPopup">
      <!-- 提示弹窗 -->
      <div class="popup-content">
        <div class="header">
          <div class="left">
            {{ title }}
          </div>
          <div class="right">
            <span class="close" @click="closeInfoPopup">X</span>
          </div>
        </div>
        <div class="content">
          <!-- 判断 content插槽 是否存在 -->
          <template v-if="$slots.content">
            <div class="nuxt__wrap-cnt">
              <slot name="content" />
            </div>
          </template>
          <template v-else>
            <div v-if="content" class="nuxt__wrap-cnt" v-html="content"></div>
          </template>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PopupSelect",
  props: {
    title: String,
    content: String,
  },
  data() {
    return {};
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // 关闭弹窗
    closeInfoPopup(e) {
      this.$emit("popupUse", e);
    },
  },
};
</script>

<style lang="less" scoped>
// 弹窗
.popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50001;
  background: rgba(0, 0, 0, 0.5);

  .popup-content {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 50002;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 12px 10px 12px;

      .left {
        display: inline-block;
        width: 100%;
        max-width: calc(100%-40px);
        /* no */
        flex-shrink: 1;
        box-sizing: border-box;

        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        color: #333333;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .right {
        width: 40px;
        flex-basis: 40px;
        position: relative;
        flex-shrink: 0;

        .close {
          position: absolute;
          top: -14px;
          right: 4px;
          font-size: 18px;
          width: 20px;
          height: 20px;
        }
      }
    }

    .content {
      background-color: #fff;
    }
  }
}

/* 设置持续时间和动画函数 */
.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.5s linear;
}

.popup-enter,
.popup-leave-to {
  opacity: 0;
}
</style>