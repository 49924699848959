<template>
    <div class="AdBlock">
        <div class="AdBlock-head" @click="handleClose">
            <div></div>
            <div class="AdBlock-title">广告 {{ formattedTime }} 秒后 可关闭</div>
            <div class="AdBlock-close" v-if="cancleClose">X</div>
            <div class="AdBlock-close" v-if="!cancleClose"></div>
        </div>
        <div class="AdBlock-content">
            <h1>广告</h1>
            <!-- <amp-ad width="100vw" height="320" type="adsense" data-ad-client="ca-pub-8560296254348782"
                data-ad-slot="7212946772" data-auto-format="rspv" data-full-width="">
                <div overflow=""></div>
            </amp-ad> -->
            <InArticleAdsense data-ad-client="ca-pub-8560296254348782" data-ad-slot="7212946772"></InArticleAdsense>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdBlock',
    props: {
        onClick: {
            type: Function,
            default: () => { },
        },
        onClose: {
            type: Function,
            default: () => { },
        },
        second: {
            default: 3,
            type: Number,
            require: true
        }
    },
    created() {
        this.handleCanclose();
    },
    data() {
        return {
            remainingSeconds: this.second,
            cancleClose: false
        }
    },
    methods: {
        handleCanclose() {
            const _time = setTimeout(() => {
                this.remainingSeconds = this.remainingSeconds - 1
                if (this.remainingSeconds == 0) {
                    this.cancleClose = true;
                    clearTimeout(_time)
                } else {
                    this.handleCanclose()
                }
            }, 1000);
        },
        handleClose() {
            if (this.cancleClose) {
                this.onClose();
            }
        }
    },
    computed: {
        formattedTime() {
            // const minutes = Math.floor(this.remainingSeconds / 60);
            const seconds = this.remainingSeconds % 60;
            // return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            return seconds
        }
    },
}
</script>

<style lang="less" scoped>
.AdBlock {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;

    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    color: #fff;

    &-head {
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    &-title {}

    &-close {}

    &-content {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        padding-top: 50px;
        box-sizing: border-box;
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0em;

        color: #FFFFFF;

    }
}
</style>